<template>
    <!--begin::Dashboard-->
    <div :class="fullscreen ? 'displayFullScreen' : 'card pt-5 pl-5 pb-5'" :style="fullscreen ? 'height:100%' : 'height:85vh'">
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <div class="d-flex align-items-center flex-row justify-content-between mb-5">
                    <b-skeleton type="button" width="12%"></b-skeleton>
                    <b-skeleton type="input" width="40%"></b-skeleton>
                    <span></span>
                </div>
                <div class=" mt-5 d-flex flex-column justify-content-around">
                    <div class=" mt-5 d-flex flew-row justify-content-between align-items-center">
                        <div class="mt-5" style="width:70%">
                            <b-skeleton width="85%"></b-skeleton>
                            <b-skeleton type="input" width="85%"></b-skeleton>
                        </div>
                        <div class="mr-10 mt-5" style="width:30%">
                            <b-skeleton width="95%"></b-skeleton>
                            <b-skeleton type="input" width="95%"></b-skeleton>
                        </div>
                    </div>
                    <div class="mt-10" style="width:100%">
                        <b-skeleton></b-skeleton>
                        <b-skeleton type="input" class="mt-2"></b-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center">
                    <b-skeleton class="mr-3" type="button"></b-skeleton>
                    <b-skeleton type="button"></b-skeleton>
                </div>
            </template>
            <div
                :style="mobile() ? 'height:15vh' : 'height:7vh'"
                class="d-flex align-items-center flex-row justify-content-between align-items-center mb-5"
                :class="mobile() ? 'flex-wrap ' : ''"
            >
                <div :style="mobile() ? 'width:100% ' : ''" class="d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <router-link
                            :to="{
                                name: !classData.on ? 'lessonItems/editor' : 'classes/edit',
                                params: { id: !classData.on ? $route.params.id : classData.id }
                            }"
                            replace
                        >
                            <span class="btn btn-outline-danger btn-md">
                                <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                    <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                                </span>
                                {{ $t('CONTROLLERS.BACK') }}
                            </span>
                        </router-link>
                        <button
                            v-if="!mobile()"
                            class="svg-icon svg-icon-xl svg-icon-primary ml-5"
                            :class="showMenu ? 'svg-icon-danger' : 'svg-icon-primary'"
                            @click="showMenu = !showMenu"
                            v-b-tooltip.hover.right="`${showMenu ? 'Fermer la liste des éléments' : 'Afficher la liste des éléments'}`"
                        >
                            <inline-svg src="/media/svg/icons/Text/Menu.svg" />
                        </button>
                    </div>
                    <div v-if="mobile()">
                        <button
                            v-if="!mobile()"
                            class="svg-icon svg-icon-xl mr-2"
                            :class="fullscreen ? 'svg-icon-danger' : 'svg-icon-primary'"
                            v-b-tooltip.hover.top="'Plein écran'"
                            @click="fullscreen = !fullscreen"
                        >
                            <inline-svg src="/media/svg/icons/General/Expand-arrows.svg" />
                        </button>
                        <router-link
                            :to="{ name: 'lessonItems/editor', params: { id: this.$route.params.id, item: this.items[itemView].order } }"
                            v-if="!classData.on"
                            replace
                        >
                            <button class="svg-icon svg-icon-xl svg-icon-primary mr-2" v-b-tooltip.hover.top="'Modifier cet item'">
                                <inline-svg src="/media/svg/icons/General/Edit.svg" />
                            </button>
                        </router-link>
                    </div>
                </div>
                <div :style="mobile() ? 'max-width:100% ' : 'max-width:50%'" :class="mobile() ? 'mt-2' : ''">
                    <h1 style="max-width:100%" class="truncate">{{ lesson.title }}</h1>
                </div>
                <div v-if="!mobile()">
                    <button
                        class="svg-icon svg-icon-xl mr-2"
                        :class="fullscreen ? 'svg-icon-danger' : 'svg-icon-primary'"
                        v-b-tooltip.hover.top="'Plein écran'"
                        @click="toggleFullScreen()"
                    >
                        <inline-svg src="/media/svg/icons/General/Expand-arrows.svg" />
                    </button>
                    <router-link
                        :to="{ name: 'lessonItems/editor', params: { id: this.$route.params.id, item: this.items[itemView].order } }"
                        v-if="!classData.on"
                        replace
                    >
                        <button class="svg-icon svg-icon-xl svg-icon-primary mr-2" v-b-tooltip.hover.top="'Modifier cet item'">
                            <inline-svg src="/media/svg/icons/General/Edit.svg" />
                        </button>
                    </router-link>
                </div>
            </div>
            <div :style="fullscreen ? 'height:92%' : ''" style="width: 100%" class="d-flex flex-row justify-content-between">
                <div
                    v-if="showMenu"
                    class="pr-5 scrollPanel"
                    style="background-color:white;height:100%;overflow-y:scroll"
                    :style="
                        `${mobile() ? 'position:absolute;top:0;left:0;overflow-y:hidden;z-index:9999;width:75%' : ''}
                        ;${showMenu ? 'width:30%' : 'width:5%'}`
                    "
                >
                    <div v-if="mobile()" style="width:100%" class="mb-4 pb-4 d-flex justify-content">
                        <button class="btn btn-pill btn-outline-danger" @click="showMenu = !showMenu">Fermer</button>
                    </div>
                    <div
                        @mouseover="
                            e => {
                                item.order <= showMax || !lesson.linear ? menuToogleBg(e) : '';
                            }
                        "
                        @mouseout="
                            e => {
                                item.order <= showMax || !lesson.linear ? menuToogleBg(e) : '';
                            }
                        "
                        style="margin-bottom:10px;"
                        :style="`${showMenu ? '' : 'height:4vh;'}`"
                        class="mt-1 px-1 py-2 d-flex flex-row justify-content-between align-items-center"
                        v-for="item in items"
                        :key="item.id"
                        @click="item.order <= showMax || !lesson.linear ? goTo(item.order - 1) : ''"
                    >
                        <div
                            class="d-flex flex-row justify-content-between align-items-center "
                            :class="item.order > showMax && lesson.linear ? 'text-muted' : ''"
                            style="width:70%"
                        >
                            <div
                                style="width: 10%;"
                                :style="showMenu ? 'width:100%;text-align:center' : 'width:100%'"
                                :class="item.order == itemView + 1 ? 'font-weight-bold' : ''"
                                v-if="showMenu"
                            >
                                <div>
                                    <span v-if="item.survey && item.survey.type === 1" class="courseIcon">
                                        <div
                                            class="svg-icon"
                                            :class="[
                                                showMenu === true ? 'svg-icon-3x' : 'svg-icon-md',
                                                item.order === itemView + 1 ? 'svg-icon-warning' : 'svg-icon-grey'
                                            ]"
                                        >
                                            <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                        </div>
                                    </span>
                                    <span v-else-if="item.media_id" :title="item.media.name" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1 && !item.media.mime_type.includes('pdf')"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/svg/icons/Course/media.svg"
                                            alt="(Media)"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1 && !item.media.mime_type.includes('pdf')"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/media.png"
                                            alt="(Media)"
                                        />
                                        <img
                                            v-if="item.order === itemView + 1 && item.media.mime_type.includes('pdf')"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/pdf_noir.png"
                                            alt="(Media)"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1 && item.media.mime_type.includes('pdf')"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/pdf_gris.png"
                                            alt="(Media)"
                                        />
                                    </span>
                                    <span v-else-if="item.survey_item_id" :title="item.surveyItems.question" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/svg/icons/Course/question.svg"
                                            alt="(Question)"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/question.png"
                                            alt="(Media)"
                                        />
                                    </span>
                                    <span v-else-if="item.survey_id" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/svg/icons/Course/QCM.svg"
                                            alt="(QCM)"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/QCM.png"
                                            alt="(Media)"
                                        />
                                    </span>
                                    <span v-else-if="item.interactives_items_id" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/svg/icons/Course/media.svg"
                                            alt="(Media)"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            src="../../../../public/media/png/icons/Course/media.png"
                                            alt="(Media)"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                v-if="showMenu"
                                style="width:90%"
                                class="hide"
                                :class="item.order == itemView + 1 ? 'font-weight-bold' : ''"
                            >
                                <span v-if="item.media_id">
                                    {{ item.media.name }}
                                </span>
                                <span v-if="item.survey_item_id">
                                    {{ item.surveyItems.title_question ? item.surveyItems.title_question : item.surveyItems.question }}
                                </span>
                                <span v-if="item.survey_id">
                                    {{ item.survey.title }}
                                </span>
                                <span v-if="item.interactives_items_id">
                                    {{ item.interaction_title }}
                                </span>
                            </div>
                        </div>
                        <div v-if="item.order > showMax && lesson.linear" class="svg-icon svg-icon-md svg-icon-muted">
                            <inline-svg src="/media/svg/icons/General/Lock.svg" />
                        </div>
                        <div v-else-if="item.survey_id">
                            <div v-if="response.survey[item.survey.id].valid == true">
                                <span class="svg-icon svg-icon-md svg-icon-success">
                                    <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                                </span>
                                <span class="text-success font-italic text-md" v-if="showMenu"
                                    >( {{ response.survey[item.survey.id].result + '%' }} )</span
                                >
                            </div>
                            <div v-else class="svg-icon svg-icon-md svg-icon-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                            </div>
                        </div>
                        <div v-else-if="item.survey_item_id">
                            <div v-if="response[item.surveyItems.id].valid == true" class="svg-icon svg-icon-md svg-icon-success">
                                <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                            </div>
                            <div v-else class="svg-icon svg-icon-md svg-icon-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewer" class="d-flex flex-column justify-content-between" style="width:100%;height:100%">
                    <div
                        v-if="fullscreen === false"
                        :style="mobile() ? 'height:10%' : 'height:5%'"
                        style="width:100%"
                        class="d-flex flex-column justify-content-between"
                    >
                        <div class="d-flex flex-row align-items-center justify-content-center" style="width:100%">
                            <button
                                @click="previous()"
                                :style="progress_bar.value <= 1 ? 'display:none' : 'display:block'"
                                class="svg-icon svg-icon-xl svg-icon-primary mr-2"
                                v-b-tooltip.hover.top="'Précédent'"
                            >
                                <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
                            </button>
                            <div style="width:90%">
                                <b-progress :max="progress_bar.max" style="width:100%" width="100%" height="2vh">
                                    <b-progress-bar :value="progress_bar.value">
                                        <span v-if="!end || progress_bar.value < progress_bar.max"
                                            >{{ progress_bar.value }} / {{ progress_bar.max }}</span
                                        >
                                        <span v-else-if="progress_bar.value == progress_bar.max && end">Terminé</span>
                                    </b-progress-bar>
                                </b-progress>
                            </div>
                            <button class="svg-icon svg-icon-xl ml-2" :class="end ? 'svg-icon-warning' : 'svg-icon-grey'">
                                <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                            </button>
                        </div>
                    </div>
                    <!-- height: 100%; -->
                    <div
                        id="item"
                        style="width: auto;" 
                        :style="items[itemView] && !items[itemView].media_id ? 'overflow-y: auto;min-height: 65% ' : 'max-height: 85%'"
                        class="d-flex flex-row justify-content-around"
                        :class="items[itemView] && !items[itemView].media_id ? 'scrollPanel' : ''"
                    >
                        <div class="p-3 rounded" style="width: 90%; max-height : 100%">
                            <!-- Media  -->
                            <div
                                v-if="items[itemView].media_id"
                                style="width:90%; height: 100%; margin: 0 auto;"
                                class="d-flex flex-column justify-content-around align-items-center"
                            >
                                <div v-if="items[itemView].media.description" class="my-5">
                                    <h2>{{ items[itemView].media.description }}</h2>
                                </div>
                                <div style="width: 100%; height: 100%" class="d-flex justify-content-around align-items-center">
                                    <video
                                        oncontextmenu="return false;"
                                        v-if="items[itemView].media.mime_type.includes('video')"
                                        width="100%"
                                        style="max-height: 100%"
                                        :key="items[itemView].media.url"
                                        controls
                                        disablePictureInPicture
                                        controlsList="nodownload noremoteplayback"
                                    >
                                        <source :src="items[itemView].media.url" />
                                    </video>
                                    <img
                                        style="max-width: 100%; max-height: 60vh !important;"
                                        v-if="items[itemView].media.mime_type.includes('image')"
                                        :src="items[itemView].media.url"
                                        :alt="items[itemView].media.name"
                                    />
                                    <div style="width: 100%; height: 60vh" v-if="items[itemView].media.mime_type.includes('zip')">
                                        <iframe
                                            v-if="items[itemView].media.mime_type.includes('zip')"
                                            :src="
                                                items[itemView].media.url
                                                    .split('.')
                                                    .slice(0, -1)
                                                    .join('.') + '/index.html'
                                            "
                                            frameborder="0"
                                            style="width: 100%; height: 100%; resize: both; margin-top: 2rem;"
                                        >
                                        </iframe>
                                    </div>
                                    <div style="width: 100%;" v-if="items[itemView].media.mime_type.includes('pdf')">
                                        <embed
                                            :src="items[itemView].media.url + '#toolbar=0&navpanes=0&scrollbar=0'"
                                            type="application/pdf"
                                            style="width: 100%;"
                                            :style="mobile() ? 'height: 50vh;' : fullscreen ? 'height: 67vh;' : 'height:57vh;'"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="items[itemView].interactive_id"
                                style="width:90%; height: 100%; margin: 0 auto;"
                                class="d-flex flex-column justify-content-around align-items-center"
                            >
                                <div style="width: 100%; height: 100%">
                                    <iframe
                                        :src="
                                            items[itemView].interactives_item.media_url
                                                .split('.')
                                                .slice(0, -1)
                                                .join('.') +
                                                '/index.html?question=' +
                                                items[itemView].interactive_id +
                                                '&mode=0'
                                        "
                                        frameborder="0"
                                        style="width: 100%; height: 100%; resize: both;"
                                    >
                                    </iframe>
                                </div>
                            </div>
                            <!-- Question  -->
                            <div
                                v-if="items[itemView].survey_item_id"
                                style="width:100%;height:100%"
                                class="d-flex flex-column justify-content-around align-items-center"
                            >
                                <div
                                    v-if="response[items[itemView].surveyItems.id].valid === true"
                                    class="bg-success rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width:100%; height:7vh"
                                >
                                    <span class="text-white">Félicitations ! Vous avez répondu juste !</span>
                                    <button
                                        v-if="response[items[itemView].surveyItems.id].valid && progress_bar.value != progress_bar.max"
                                        class="btn btn-pill btn-outline-white"
                                        @click="next()"
                                    >
                                        Suivant
                                    </button>
                                    <button
                                        v-else-if="progress_bar.value == progress_bar.max"
                                        class="btn btn-pill btn-outline-white"
                                        @click="finish()"
                                    >
                                        Terminer
                                    </button>
                                </div>
                                <div
                                    v-if="response[items[itemView].surveyItems.id].valid === false"
                                    class="bg-danger rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width:100%; min-height:5vh"
                                >
                                    <div class="d-flex flex-column my-5" style="max-width:75%">
                                        <span class="text-white">Vous n'avez pas répondu juste à cette question.</span>
                                        <span
                                            class="text-white border-white"
                                            :class="items[itemView].surveyItems.message ? 'border-bottom pb-5 my-2' : 'mt-2'"
                                        >
                                            Vous pouvez le recommencer immédiatement, cependant, nous vous invitons à revoir les pages
                                            précédentes.
                                        </span>
                                        <span class="text-white mt-5" v-if="items[itemView].surveyItems.message">
                                            Indication du concepteur : {{ items[itemView].surveyItems.message }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="showQuestion">
                                    <h2>{{ items[itemView].surveyItems.question }}</h2>
                                </div>
                                <div v-if="showQuestion" style="height: 40%;width:100%" class="d-flex flex-column justify-content-around">
                                    <div class="d-flex flex-row justify-content-around align-items-center">
                                        <b-form-checkbox
                                            size="lg"
                                            id="anwser_a"
                                            v-model="response[items[itemView].surveyItems.id].anwser_a"
                                            :disabled="response[items[itemView].surveyItems.id].valid"
                                        />
                                        <label for="anwser_a" class="h3 m-0" style="width:80%">
                                            {{ items[itemView].surveyItems.answer_a }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-row justify-content-around align-items-center">
                                        <b-form-checkbox
                                            size="lg"
                                            id="anwser_b"
                                            v-model="response[items[itemView].surveyItems.id].anwser_b"
                                            :disabled="response[items[itemView].surveyItems.id].valid"
                                        />
                                        <label for="anwser_b" class="h3 m-0" style="width:80%">
                                            {{ items[itemView].surveyItems.answer_b }}
                                        </label>
                                    </div>
                                    <div
                                        v-if="items[itemView].surveyItems.answer_c && items[itemView].surveyItems.answer_c.trim() != ''"
                                        class="d-flex flex-row justify-content-around align-items-center"
                                    >
                                        <b-form-checkbox
                                            size="lg"
                                            id="anwser_c"
                                            v-model="response[items[itemView].surveyItems.id].anwser_c"
                                            :disabled="response[items[itemView].surveyItems.id].valid"
                                        />
                                        <label for="anwser_c" class="h3 m-0" style="width:80%">
                                            {{ items[itemView].surveyItems.answer_c }}
                                        </label>
                                    </div>
                                    <div
                                        v-if="items[itemView].surveyItems.answer_d && items[itemView].surveyItems.answer_d.trim() != ''"
                                        class="d-flex flex-row justify-content-around align-items-center"
                                    >
                                        <b-form-checkbox
                                            size="lg"
                                            id="anwser_d"
                                            v-model="response[items[itemView].surveyItems.id].anwser_d"
                                            :disabled="response[items[itemView].surveyItems.id].valid"
                                        />
                                        <label for="anwser_d" class="h3 m-0" style="width:80%">
                                            {{ items[itemView].surveyItems.answer_d }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- QCM  -->
                            <div v-if="items[itemView].survey_id" class="d-flex flex-column justify-content-around align-items-center">
                                <div
                                    v-if="response.survey[items[itemView].survey_id].valid === true"
                                    class="bg-success rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width:100%; height:7vh"
                                >
                                    <span class="text-white">
                                        Félicitations ! Vous avez réussi ce test avec un score de
                                        {{ response.survey[items[itemView].survey_id].result }}%
                                    </span>
                                    <button
                                        v-if="response.survey[items[itemView].survey_id].valid && progress_bar.value != progress_bar.max"
                                        class="btn btn-pill btn-outline-white"
                                        @click="next()"
                                    >
                                        Suivant
                                    </button>
                                    <button
                                        v-else-if="progress_bar.value == progress_bar.max"
                                        class="btn btn-pill btn-outline-white"
                                        @click="finish()"
                                    >
                                        Terminer
                                    </button>
                                </div>
                                <div
                                    v-else-if="response.survey[items[itemView].survey_id].valid === false && !showQuestion"
                                    class="bg-danger mt-5 rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width:100%; min-height:5vh"
                                >
                                    <div class="d-flex flex-column my-5" style="max-width:75%">
                                        <span class="text-white">Vous n'avez pas réussi ce test.</span>
                                        <span
                                            class="text-white border-white pb-5"
                                            :class="items[itemView].survey.message ? 'border-bottom pb-2 my-2' : 'mt-2'"
                                        >
                                            Vous pouvez le recommencer immédiatement, cependant, nous vous invitons à revoir les pages
                                            précédentes.
                                        </span>
                                        <span class="text-white mt-5" v-if="items[itemView].survey.message">
                                            Indication du concepteur : {{ items[itemView].survey.message }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="showQuestion" style="width:90%">
                                    <div
                                        v-for="(question, index) in items[itemView].surveyItems"
                                        :key="question.id"
                                        class="my-5 pb-5 border-bottom border-secondary"
                                        :class="question.solution != question.answered && showWrongAnswers ? 'wrong-answer-border' : ''"
                                    >
                                        <div class="mb-10">
                                            <h2>
                                                <i class="text-muted mr-5">{{ index + 1 + '/' + items[itemView].surveyItems.length }}</i
                                                >{{ question.question }}
                                            </h2>
                                        </div>
                                        <div style="height: 40%;width:100%" class="d-flex flex-column justify-content-around">
                                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                                <b-form-checkbox
                                                    size="md"
                                                    :id="question.id + 'a'"
                                                    v-model="response[question.id].anwser_a"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                />
                                                <label
                                                    :for="question.id + 'a'"
                                                    class="h3 m-0"
                                                    style="width:80%"
                                                    :id="'label' + question.id + 'a'"
                                                    :class="getClass(question, 'a')"
                                                >
                                                    {{ question.answer_a }}
                                                </label>
                                            </div>
                                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                                <b-form-checkbox
                                                    size="md"
                                                    :id="question.id + 'b'"
                                                    v-model="response[question.id].anwser_b"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                />
                                                <label
                                                    :for="question.id + 'b'"
                                                    class="h3 m-0"
                                                    style="width:80%"
                                                    :id="'label' + question.id + 'b'"
                                                    :class="getClass(question, 'b')"
                                                >
                                                    {{ question.answer_b }}
                                                </label>
                                            </div>
                                            <div
                                                v-if="question.answer_c != ' ' && question.answer_c != '' && question.answer_c"
                                                class="d-flex flex-row justify-content-around align-items-center my-2"
                                            >
                                                <b-form-checkbox
                                                    size="md"
                                                    :id="question.id + 'c'"
                                                    v-model="response[question.id].anwser_c"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                />
                                                <label
                                                    :for="question.id + 'c'"
                                                    class="h3 m-0"
                                                    style="width:80%;"
                                                    :id="'label' + question.id + 'c'"
                                                    :class="getClass(question, 'c')"
                                                >
                                                    {{ question.answer_c }}
                                                </label>
                                            </div>
                                            <div
                                                v-if="question.answer_d != ' ' && question.answer_d != '' && question.answer_d"
                                                class="d-flex flex-row justify-content-around align-items-center my-2"
                                            >
                                                <b-form-checkbox
                                                    size="md"
                                                    :id="question.id + 'd'"
                                                    v-model="response[question.id].anwser_d"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                />
                                                <label
                                                    :for="question.id + 'd'"
                                                    class="h3 m-0"
                                                    style="width:80%"
                                                    :id="'label' + question.id + 'd'"
                                                    :class="getClass(question, 'd')"
                                                >
                                                    {{ question.answer_d }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%; height: 10%" class="d-flex flex-row justify-content-center align-items-center">
                        <button v-if="progress_bar.value != 1" class="btn btn-sm btn-pill btn-outline-primary mx-2" @click="previous()">
                            Précédent
                        </button>
                        <button
                            v-if="
                                (items[itemView].survey_id && !response.survey[items[itemView].survey_id].valid && showQuestion) ||
                                    (items[itemView].survey_item_id && !response[items[itemView].surveyItems.id].valid && showQuestion)
                            "
                            class="btn btn-md btn-pill btn-primary mr-2"
                            @click="valid()"
                        >
                            Valider {{ items[itemView].survey_item_id ? 'la question' : 'mes réponses' }}
                        </button>
                        <button
                            v-else-if="
                                items[itemView].survey_id &&
                                    response.survey[items[itemView].survey_id].valid &&
                                    response.survey[this.items[this.itemView].survey_id].result < 100 &&
                                    showQuestion
                            "
                            class="btn btn-md btn-pill btn-primary"
                            @click="valid()"
                        >
                            Améliorer mon score
                        </button>
                        <button
                            v-if="
                                (items[itemView].survey_id && !response.survey[items[itemView].survey_id].valid && !showQuestion) ||
                                    (items[itemView].survey_item_id && !response[items[itemView].surveyItems.id].valid && !showQuestion)
                            "
                            class="btn btn-md btn-pill btn-primary"
                            @click="resetFail()"
                        >
                            Réessayer maintenant
                        </button>
                        <button
                            v-if="
                                (items[itemView].survey_id &&
                                    response.survey[items[itemView].survey_id].valid &&
                                    progress_bar.value != progress_bar.max) ||
                                    (items[itemView].survey_item_id &&
                                        response[items[itemView].surveyItems.id].valid &&
                                        progress_bar.value != progress_bar.max) ||
                                    (!items[itemView].survey_item_id &&
                                        !items[itemView].survey_id &&
                                        progress_bar.value != progress_bar.max) ||
                                    (!lesson.linear && progress_bar.value != progress_bar.max)
                            "
                            class="btn btn-sm btn-pill btn-outline-primary mx-2"
                            @click="next()"
                        >
                            Suivant
                        </button>
                        <router-link
                            v-if="progress_bar.value == progress_bar.max"
                            :to="{
                                name: !classData.on ? 'lessonItems/editor' : 'classes/edit',
                                params: { id: !classData.on ? $route.params.id : classData.id }
                            }"
                            replace
                        >
                            <button class="btn btn-pill btn-outline-primary mx-2">Terminer</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </b-skeleton-wrapper>
        <div
            v-if="submit"
            style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background-color: #ffffff9e;
                backdrop-filter: blur(5px);
            "
            class="d-flex flex-column justify-content-around align-items-center"
        >
            <div style="z-index:200;" class="d-flex flex-column justify-content-around align-items-center">
                <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
                <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { LIST_LESSON_ITEMS } from '@/core/services/store/api/lessonitems.service';
import { LIST_LESSON } from '@/core/services/store/api/lesson.service';
import { LIST_CLASSE } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
//import ApiService from '@/core/services/api.service';
//import i18n from '@/core/plugins/vue-i18n.js';

export default {
    name: 'admin-form-edit-lesson',
    components: {},
    mounted() {
        this.startLoading();
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_LESSON_ITEMS, this.$route.params.id).then(() => {
                const test = [];
                const survey = [];
                this.items.forEach(element => {
                    if (element.survey_id) {
                        element.surveyItems.forEach(question => {
                            test[question.id] = {
                                anwser_a: false,
                                anwser_b: false,
                                anwser_c: false,
                                anwser_d: false
                            };
                        });
                        survey[element.survey_id] = {
                            valid: null,
                            result: null
                        };
                    } else if (element.survey_item_id) {
                        test[element.surveyItems.id] = {
                            anwser_a: false,
                            anwser_b: false,
                            anwser_c: false,
                            anwser_d: false,
                            valid: null
                        };
                    }
                });
                test.survey = survey;
                this.response = test;
                if (this.$route.params.item) {
                    this.goTo(this.$route.params.item - 1);
                }
            });
            this.$store.dispatch(LIST_LESSON, this.$route.params.id);
            this.$store.dispatch(LIST_CLASSE, this.lesson.classe_id);
        } else {
            this.$router.push({ name: 'admin/dashboard' });
        }

        this.showMenu = !this.mobile();
    },
    computed: {
        ...mapGetters({ lesson: 'getLesson', items: 'getLessonItems', classData: 'getClasse' })
    },
    data() {
        return {
            loading: false,
            loadingTime: 0,
            fullscreen: false,
            maxLoadingTime: 1,
            show: true,
            showMenu: true,
            showMax: 1,
            showQuestion: true,
            showWrongAnswers: false,
            end: false,
            submit: false,
            title: null,
            itemView: 0,
            response: {},
            progress_bar: {
                value: 0,
                max: 0
            }
        };
    },
    methods: {
        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            if (this.fullscreen === true) {
                this.showMenu = false;
            } else {
                this.showMenu = true;
            }
        },
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval);
            this.$_loadingTimeInterval = null;
        },
        startLoading() {
            this.loading = true;
            this.loadingTime = 0;
        },
        next() {
            if (this.items[this.itemView + 1] != undefined) {
                this.itemView++;
                this.resetFail();
                this.progress_bar.value++;
                this.showMax++;
            }
        },
        previous() {
            if (this.itemView - 1 >= 0) {
                this.itemView--;
                this.resetFail();
                this.progress_bar.value--;
            }
        },
        goTo(elId) {
            if (this.items[elId].id != undefined) {
                this.itemView = elId;
                this.resetFail();
                this.progress_bar.value = elId + 1;
                //this.show = false;
            }
        },
        valid() {
            if (this.items[this.itemView].survey_id) {
                let goodawnser = 0;
                let allQuestion = 0;
                this.items[this.itemView].surveyItems.forEach(question => {
                    const id = question.id;
                    let fullresponse = '';
                    const el = this.response[id];
                    if (el.anwser_a) {
                        fullresponse += 'A';
                    }
                    if (el.anwser_b) {
                        fullresponse += 'B';
                    }
                    if (el.anwser_c) {
                        fullresponse += 'C';
                    }
                    if (el.anwser_d) {
                        fullresponse += 'D';
                    }
                    if (fullresponse == question.solution) {
                        goodawnser++;
                    }
                    question.answered = fullresponse;
                    allQuestion++;
                });

                const score = parseInt((goodawnser / allQuestion) * 100);
                if (score >= this.items[this.itemView].survey.goal) {
                    this.response.survey[this.items[this.itemView].survey_id].valid = true;
                    this.response.survey[this.items[this.itemView].survey_id].result = score;
                    if (this.progress_bar.value == this.progress_bar.max) {
                        this.end = true;
                    }
                    this.showWrongAnswers = true;
                } else {
                    this.showQuestion = false;
                    this.response.survey[this.items[this.itemView].survey_id].valid = false;
                    this.response.survey[this.items[this.itemView].survey_id].result = score;
                }
            } else if (this.items[this.itemView].survey_item_id) {
                let fullresponse = '';
                if (this.response[this.items[this.itemView].surveyItems.id].anwser_a) {
                    fullresponse += 'A';
                }
                if (this.response[this.items[this.itemView].surveyItems.id].anwser_b) {
                    fullresponse += 'B';
                }
                if (this.response[this.items[this.itemView].surveyItems.id].anwser_c) {
                    fullresponse += 'C';
                }
                if (this.response[this.items[this.itemView].surveyItems.id].anwser_d) {
                    fullresponse += 'D';
                }
                if (fullresponse == this.items[this.itemView].surveyItems.solution) {
                    this.response[this.items[this.itemView].surveyItems.id].valid = true;
                    if (this.progress_bar.value == this.progress_bar.max) {
                        this.end = true;
                    }
                } else {
                    //this.showQuestion = false;
                    this.response[this.items[this.itemView].surveyItems.id].valid = false;
                }
            }
            this.itemView = this.itemView++;
            this.progress_bar.value = this.itemView + 1;
            window.document.getElementById('item').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        resetFail() {
            if (this.items[this.itemView].survey_id) {
                if (!this.response.survey[this.items[this.itemView].survey_id].valid) {
                    this.items[this.itemView].surveyItems.forEach(question => {
                        this.response[question.id].anwser_a = false;
                        this.response[question.id].anwser_b = false;
                        this.response[question.id].anwser_c = false;
                        this.response[question.id].anwser_d = false;
                    });
                }
            } else if (this.items[this.itemView].survey_item_id) {
                if (!this.response[this.items[this.itemView].surveyItems.id].valid) {
                    this.response[this.items[this.itemView].surveyItems.id].anwser_a = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_b = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_c = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_d = false;
                }
            }
            this.showQuestion = true;
            this.showWrongAnswers = false;
        },
        mobile() {
            if (window.screen.availWidth <= 992) {
                return true;
            } else {
                return false;
            }
        },
        finish() {
            if (!this.classData.on) {
                this.$router.push({ name: 'lessonItems/editor', params: { id: this.$route.params.id } });
            } else {
                this.$router.push({ name: 'classes/edit', params: { id: this.classData.id } });
            }
        },
        menuToogleBg(event) {
            if (getComputedStyle(event.currentTarget).backgroundColor !== 'rgba(181, 181, 195, 0.17)') {
                event.currentTarget.style.backgroundColor = 'rgba(181, 181, 195, 0.17)';
            } else {
                event.currentTarget.style.backgroundColor = 'white';
            }
        },
        getClass(question, letter) {
            if (question.solution != question.answered && this.showWrongAnswers) {
                if (question.solution.toLowerCase() == letter) {
                    return 'good-answer';
                } else {
                    return 'wrong-answer';
                }
            }
        }
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clearLoadingTimeInterval();

                if (newValue) {
                    this.$_loadingTimeInterval = setInterval(() => {
                        this.loadingTime++;
                    }, 500);
                }
            }
        },
        loadingTime(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === this.maxLoadingTime) {
                    this.loading = false;
                }
            }
        }
    },
    created() {
        this.$_loadingTimeInterval = null;
        this.progress_bar.max = this.items.length;
        if (this.$route.params.item) {
            this.itemView = this.$route.params.item;
        }
        this.progress_bar.value = this.items[this.itemView].order;
        if (!this.lesson.linear) {
            document.addEventListener('keydown', e => {
                if (e.keyCode === 37) {
                    this.previous();
                } else if (e.keyCode === 39) {
                    this.next();
                }
            });
        }
    }
};
</script>
<style>
.font-weight-bold {
    font-weight: 1000 !important;
}

.nl2br {
    max-height: 45%;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.scrollPanel::-webkit-scrollbar-track {
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar {
    width: 3px;
    background-color: #eee;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.displayFullScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 1em;
    z-index: 9999;
    overflow: hidden;
}

#item {
    background-color: white;
}

.wrong-answer-border {
    border: 2px solid red !important;
    border-radius: 3px;
    padding: 10px;
}

.wrong-answer {
    text-decoration: line-through;
    color: red;
}

.good-answer {
    color: green;
}

.courseIcon img {
    width: 50%;
    fill: red !important;
}

@media screen and (max-width: 900px) {
    .hide {
        position: absolute;
        z-index: -11110;
    }

    .width-media {
        width: 90% !important;
    }
}
</style>
